<template>
    <div class="card-product nearby">
        <router-link :to="item.url" target="_blank">
            <!--        <a href="#" target="_blank" rel="noopener noreferrer">-->
            <div class="img">
                <div class="img-ratio-house">
                    <img src="https://s3-us-west-2.amazonaws.com/ibigfun/house/img/8800000245/image%20(12)20210630170219.jpg" alt="" class="w-100">
                </div>
            </div>
            <div class="card-body">
                <h3 class="text-truncate">{{ item.communityName }}</h3>
                <p class="address text-truncate">
                    <IconPin class="icon me-1"/>
                    {{ item.address }}
                </p>
                <div class="info-price">
                    <span v-if="item.count == 1">目前售價 {{ item.minPrice }} 萬</span>
                    <span v-if="item.count > 1">目前售價 {{ item.minPrice }} - {{ item.maxPrice }} 萬</span>
                    <span v-else>&nbsp;</span>
                </div>
                <div class="sale-tag" v-if="item.count > 0">{{ item.count }} <span class="small">筆待售物件</span></div>
                <div class="sale-tag sale-tag2" v-else><span class="small">查看社區</span></div>
            </div>
        </router-link>
    </div>
</template>
<script>
import IconPin from '@/components/icons/IconPin.vue'

export default {
    name: 'NearbyCard',
    props: {
        item: Object
    },
    components: {
        IconPin
    },
    methods: {},
}
</script>

